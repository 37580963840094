.dropzone {
  padding: 10px;
  border-radius: 4px;
  border: 2px dashed #1c527a;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.dropzone.drag-active {
  border-color: orange;
}

.dropzone.avatar-uploader {
  border-width: 0;
  padding: 0;
}

.MuiTableCell-root {
  padding: 6px 12px !important;
}

.MuiTableCell-body {
  color: inherit !important;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

a {
  color: #34aae4;
}
